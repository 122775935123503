<template>
    <div id="apps">
      <div class="container-fluid">
        <!-- Add Edit Section -->
        <div class="add-section" v-if="canAccess(['store-App','update-App'])">
          <b-modal id="app-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                   :ok-title="$t('save')" :title="$t('sidebar.apps')" @ok="submit">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <label for="">{{ $t('name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('prefix') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.prefix"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('email') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.email"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('phone') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.phone"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('short-name') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.short_name"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('percentage') }}</label>
                <b-form-input
                  type="number"
                  placeholder=""
                  v-model="payload.percentage"
                  required
                ></b-form-input>
              </div>
              <div class="feild">
                <label for="">{{ $t('ip-production') }}</label>
                <b-form-input
                  type="text"
                  placeholder=""
                  v-model="payload.domain"
                  required
                ></b-form-input>
              </div>
              <!-- <div
                class="img-preview d-flex justify-content-center align-items-center"
              >
                <img
                  class="w-100 h-100"
                  v-if="imgPreview"
                  :src="imgPreview"
                  alt=""
                />
                <span v-else>{{ $t('img-preview') }}</span>
              </div>
              <div class="feild pt-3">
                <b-form-file
                  type="text"
                  @change="getImg($event)"
                  :placeholder="$t('upload-img')"
                  required
                ></b-form-file>
              </div> -->
            </b-form>
          </b-modal>
        </div>
        <!-- Add Amount Section -->
        <div class="amount-section" v-if="canAccess(['amount-App'])">
          <b-modal id="app-amount-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                   :ok-title="$t('save')" :title="$t('sidebar.apps')" @ok="addAmount">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <label for="">{{ $t('amount') }}</label>
                <b-form-input
                  type="number"
                  placeholder=""
                  v-model="app.amount"
                  required
                ></b-form-input>
              </div>
            </b-form>
          </b-modal>
        </div>

        <!-- Add active Section -->
        <div class="active-section" v-if="canAccess(['update-App'])">
          <b-modal id="app-active-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                  :ok-title="$t('save')" :title="$t('sidebar.apps')" @ok="activeApp">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <h3>Active App</h3>
                <p>Hi dear, make sure you want this app to be active now </p>
              </div>
            </b-form>
          </b-modal>
        </div>

        <!-- Add deactive Section -->
        <div class="deactive-section" v-if="canAccess(['update-App'])">
          <b-modal id="app-deactive-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                  :ok-title="$t('save')" :title="$t('sidebar.apps')" @ok="activeApp">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <h3>Active App</h3>
                <p>Hi dear, make sure you want this app to be not active now </p>
              </div>
            </b-form>
          </b-modal>
        </div>

        <!-- Add production Section -->
        <div class="production-section" v-if="canAccess(['update-App'])">
          <b-modal id="app-production-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
                  :ok-title="$t('save')" :title="$t('sidebar.apps')" @ok="porductionApp">
            <b-form class="d-grid grid-cols-1 gap-5">
              <div class="feild">
                <h3>Production App</h3>
                <p>Hi dear, make sure you want this app to be production now </p>
              </div>
            </b-form>
          </b-modal>
        </div>
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                  <b-form-input
                    type="text"
                    :placeholder="$t('filter-name')"
                    v-model="filterData[`${$i18n.locale}_name`]"
                    required
                  ></b-form-input>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('apps-list') }}</h4>
            </template>
            <template v-slot:headerAction>
              <b-button v-if="canAccess(['store-App'])" variant="primary" @click="addInit" size="sm">{{
                  $t('add')
                }}
              </b-button>
            </template>
            <template v-slot:body>
              <b-table responsive v-if="canAccess(['get-App','delete-App','update-App'])"
                       :items="apps.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(amount)="data">
                  <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                    $ {{ data.item.amount }}
                  </div>
                </template>
                <template v-slot:cell(logo)="data">
                    <img width="75" height="50" style="object-fit: cover;" class="rounded-sm" :src="data.item.logo" alt="">
                  </template>
                <template v-slot:cell(is_active)="data">
                  <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                    <b-button variant=" iq-bg-danger" v-if="!data.item.is_active && canAccess(['update-App'])" v-b-tooltip.top="$t('Active')" @click="activeModel(data.item.referance_id)" size="md">
                      False
                    </b-button>
                    <b-button variant=" iq-bg-primary" v-if="data.item.is_active && canAccess(['update-App'])" v-b-tooltip.top="$t('Active')" @click="deactiveModel(data.item.referance_id)" size="md">
                      True
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(dev_token)="data">
                  <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                    <b-button variant=" iq-bg-info" v-b-tooltip.top="$t('copy token')" @click="copyToken(data.item.dev_token, 'Development')" size="md">
                      Dev Token
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(prod_token)="data">
                  <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                    <b-button v-if="data.item.prod_token"  variant=" iq-bg-success" v-b-tooltip.top="$t('copy token')" @click="copyToken(data.item.prod_token, 'Production')" size="md">
                      Prod Token
                    </b-button>
                    <h6 v-if="!data.item.prod_token" >
                      No Token
                    </h6>
                  </div>
                </template>
                <template v-slot:cell(is_production)="data">
                  <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                    <b-button variant=" iq-bg-danger" v-if="!data.item.is_production && canAccess(['update-App'])" v-b-tooltip.top="$t('Production')" @click="productionModel(data.item.referance_id)" size="md">
                      False
                    </b-button>
                    <b-button variant=" iq-bg-primary" v-if="data.item.is_production" size="md">
                      True
                    </b-button>
                  </div>
                </template>
                <template v-slot:cell(actions)="data">
                  <div class="actions-list d-flex align-items-center" style="gap: 5px;">
                    <b-button v-if="canAccess(['update-App'])" v-b-tooltip.top="$t('edit')"
                              variant=" iq-bg-success" @click="update(data.item)" size="md"><i
                      class="ri-ball-pen-fill p-0"></i></b-button>
                    <b-button v-if="canAccess(['amount-App'])" v-b-tooltip.top="$t('Amount')"
                              variant=" iq-bg-success" @click="addAmountModel(data.item.referance_id)" size="md"><i
                      class="ri-money-dollar-circle-fill p-0"></i></b-button>
                    <b-button v-if="canAccess(['show-AppTransaction'])" v-b-tooltip.top="$t('Show Transactions')"
                              variant=" iq-bg-success" @click="showTransactionsModel(data.item.referance_id)" size="md"><i
                      class="ri-exchange-box-fill p-0"></i></b-button>
                  </div>
                </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination
                v-model="filterData.page"
                :total-rows="apps.total"
                :per-page="apps.per_page"
              ></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'apps',
    data () {
      return {
        filterData: {
          take: 10,
          page: 1,
          ar_name: '',
          en_name: '',
          kr_name: ''
        },
        app:{
          amount: 0,
          app_id: null,
        },
        app_id: null,
        imageUrl: null,
        imgPreview: '',
        payload: {
          referance_id: '',
          name: '',
          prefix: '',
          short_name: '',
          email: '',
          phone: '',
          percentage: 0,
          domain: '',
          logo: ''
        },
      }
    },
    computed: {
      fields () {
        return [
          // {
          //   label: this.$t('logo'),
          //   key: 'logo'
          // },
          {
            label: this.$t('name'),
            key: 'name'
          },
          {
            label: this.$t('prefix'),
            key: 'prefix'
          },
          {
            label: this.$t('amount'),
            key: 'amount'
          },
          {
            label: this.$t('created-date'),
            key: 'created_at'
          },
          {
            label: this.$t('created-by'),
            key: 'created_by.name'
          },
          {
            label: 'dev token',
            key: 'dev_token'
          },
          {
            label: 'prod token',
            key: 'prod_token'
          },
          {
            label: 'is active?',
            key: 'is_active'
          },
          {
            label: 'is production?',
            key: 'is_production'
          },
          {
            label: this.$t('actions'),
            key: 'actions'
          }
        ]
      }
    },
    methods: {
      filtering () {
        this.filterData.page = 1
        this.getApps(this.filterData)
      },
      addInit () {
        this.$bvModal.show('app-modal')
      },
      async getImg (event) {
        this.imgPreview = URL.createObjectURL(event.target.files[0])
        this.payload.logo = await this.readFileAsArrayBuffer(event.target.files[0]);
      },
      readFileAsArrayBuffer(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
      },
      copyToken(token, type){
        navigator.clipboard.writeText(token)
        
        this.$bvToast.toast(type + ' Token Copied Successfully', {
          title: 'Copy Token',
          variant: 'info',
          solid: true
        })
      },
      showTransactionsModel(appId){
        this.$router.push(`/app-transactions/${appId}`)
      },
      addAmountModel (appId) {
        this.app.app_id = appId
        this.$bvModal.show('app-amount-modal')
      },
      productionModel (appId) {
        this.app_id = appId
        console.log(this.app_id)
        this.$bvModal.show('app-production-modal')
      },
      activeModel (appId) {
        this.app_id = appId
        this.$bvModal.show('app-active-modal')
      },
      deactiveModel (appId) {
        this.app_id = appId
        this.$bvModal.show('app-deactive-modal')
      },
      update (data) {
        Object.assign(this.payload, data)
        this.$bvModal.show('app-modal')
      },
      activeApp(){
        this.addActive(this.app_id).then(() => {
          this.$bvModal.hide('app-active-modal')
          this.app_id = null
          this.getApps()
        })
      },
      porductionApp(){
        this.addPorduction(this.app_id).then(() => {
          this.$bvModal.hide('app-active-modal')
          this.app_id = null
          this.getApps()
        })
      },

      submit (bvModalEvent) {
        bvModalEvent.preventDefault()
        if (!this.payload.referance_id) {
        console.log(this.payload)
        this.addApp(this.payload).then(() => {
            this.$bvModal.hide('app-modal')
            this.payload = this.resetObject(this.payload)
            this.getApps()
          })
        } else {
          this.updateApp(this.payload).then(() => {
            this.$bvModal.hide('app-modal')
            this.payload = this.resetObject(this.payload)
            this.getApps()
          })
        }
      },
      closeModal (bvModalEvent) {
        bvModalEvent.preventDefault()
        this.payload = this.resetObject(this.payload)
      },
      addAmount () {
          this.addAamount(this.app).then(() => {
            this.$bvModal.hide('app-amount-modal')
            this.app = this.resetObject(this.app)
            this.getApps()
          })
      }
    },
    watch: {
      'filterData.page': {
        handler: function (value) {
          this.getApps(this.filterData)
        },
        deep: true
      }
    },
    mounted () {
      this.authUserPermissions();
      this.getApps()
    }
  }
  </script>
  